import Subheader from '@/components/white-label/subheader/Subheader.vue'
import CardRow from '@/components/card-row/CardRow.vue'
import YourBenefits from '@/components/white-label/YourBenefits'
import StartScaling from '@/components/white-label/StartScaling'

export default {
  name: 'WhiteLabel',
  components: {
    Subheader,
    CardRow,
    YourBenefits,
    StartScaling
  },
	metaInfo () {
		return {
			title: 'Crypto Saas Solution | 1TN',
			meta: [{
				name: 'description',
				content: 'Facilitate fast and seamless processing of cryptocurrencies globally. Effortless onboarding and seamless transactions. Complete compliance with all regulations.',
			},
      {
				name: 'og:url',
				content: this.createCanonicalUrl(),
			},
			{
				name: 'og:type',
				content: 'website',
			},
			{
				name: 'og:title',
				content: 'Crypto Saas Solution | 1TN',
			},
			{
				name: 'og:description',
				content: 'Facilitate fast and seamless processing of cryptocurrencies globally. Effortless onboarding and seamless transactions. Complete compliance with all regulations.',
			},
			{
				name: 'og:image',
				content: require('@/assets/images/charts-with-shadow.png'),
			},
			{
				name: 'twitter:card',
				content: 'summary_large_image',
			},
			{
				name: 'twitter:domain',
				content: '1tn.com',
			},
			{
				name: 'twitter:url',
				content: this.createCanonicalUrl(),
			},
			{
				name: 'twitter:title',
				content: 'Crypto Saas Solution | 1TN',
			},
			{
				name: 'twitter:description',
				content: 'Facilitate fast and seamless processing of cryptocurrencies globally. Effortless onboarding and seamless transactions. Complete compliance with all regulations.',
			},
			{
				name: 'twitter:image',
				content: require('@/assets/images/charts-with-shadow.png'),
			}
    ],
			link: [{
				rel: 'canonical',
				href: this.createCanonicalUrl()
			}]
		}
	},
  computed: {
    items () {
      return [
        {
          src: require('@/assets/images/choose-integration.svg'),
          title: this.trans('white_label_page_choose_us_item_1_title'),
          description: this.trans('white_label_page_choose_us_item_1_content'),
        },
        {
          src: require('@/assets/images/choose-security.svg'),
          title: this.trans('white_label_page_choose_us_item_2_title'),
          description: this.trans('white_label_page_choose_us_item_2_content'),
        },
        {
          src: require('@/assets/images/chose-scalability.svg'),
          title: this.trans('white_label_page_choose_us_item_3_title'),
          description: this.trans('white_label_page_choose_us_item_3_content'),
        },
        {
          src: require('@/assets/images/chose-prevention.svg'),
          title: this.trans('white_label_page_choose_us_item_4_title'),
          description: this.trans('white_label_page_choose_us_item_4_content'),
        }
      ]
    }
  }
}
