<template>
  <div class="content">
    <v-row no-gutters class="flex-column mb-10 mb-md-15">
      <v-col cols="12" align-self="center">
        <h2
					class="vue-editor text-md-h2 text-body-1 text-center font-weight-black"
					v-html="trans('white_label_page_start_scaling')"
				/>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" class="d-flex flex-column flex-md-row justify-md-center align-center align-md-start">
				<StartScalingLeftBtn />
        <StartScalingRightBtn />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import StartScalingLeftBtn from '@/components/white-label/white-label-buttons/StartScalingLeftBtn'
import StartScalingRightBtn from '@/components/white-label/white-label-buttons/StartScalingRightBtn'

export default {
  name: 'StartScaling',
  components: {
		StartScalingLeftBtn,
    StartScalingRightBtn
  }
}
</script>

<style lang="scss" scoped>
.content {
  margin-bottom: 150px;
}
.sub-title {
  max-width: 667px;
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .content {
    margin-bottom: 40px;
  }
}
</style>
