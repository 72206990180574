<template>
<div class="section-wrapper">
  <v-img
		v-if="$vuetify.breakpoint.mdAndUp"
		:width="windowWidth >= 1440 ? '929' : '64%'"
		src="@/assets/images/checkout-with-shadow.png"
		class="main-image"
	/>
  <div class="content">
    <v-row no-gutters class="flex-column">
      <v-col cols="12">
        <h2
					class="vue-editor text-center text-md-h2 text-subtitle-1 font-weight-black title"
					v-html="trans('white_label_page_your_benefits')"
				/>
      </v-col>
    </v-row>
    <v-row class="justify-space-between ma-0">
      <v-col cols="12" md="4" class="pa-0 pr-6">
        <ul class="list grayText--text pa-0">
          <li
            v-for="(item, itemIndex) in items"
            :key="itemIndex"
            class="d-flex align-center item-list"
          >
            <v-img
              height="16"
              maxWidth="18"
              src="@/assets/images/mark.svg"
              class="mr-4"
              contain
              />
              <span
								class="vue-editor text-body-2 text-md-subtitle-2 span-line-height"
								v-html="item"
              />
          </li>
        </ul>
      </v-col>
      <v-col cols="12" md="8" class="pa-0">
        <v-img
					v-if="$vuetify.breakpoint.smAndDown"
					width="100%"
					src="@/assets/images/checkout-with-shadow.png"
					class="main-image"
				/>
      </v-col>
    </v-row>
  </div>
</div>
</template>

<script>
export default {
  name: 'ChooseBusiness',
  computed: {
		items () {
			return [
        this.trans('white_label_page_benefit_1'),
        this.trans('white_label_page_benefit_2'),
        this.trans('white_label_page_benefit_3'),
        this.trans('white_label_page_benefit_4')
      ]
		}
  }
}
</script>

<style lang="scss" scoped>
.section-wrapper {
  position: relative;
  &::before {
		position: absolute;
		content: "";
		width: 750px;
		height: 750px;
		top: -11px;
		right: -323px;
		border-radius: 50%;
		background: var(--v-mainColor-base);
		opacity: 0.1;
		z-index: 0;
	}
}
.content {
  margin-top: 141px;
  margin-bottom: 478px;
  position: relative;
}
.title {
  padding-bottom: 100px;
}
.item-list:not(:last-child) {
  margin-bottom: 20px;
}
.main-image {
	position: absolute !important;
	top: 80px;
	right: 8px;
	z-index: 10;
}
.span-line-height {
  line-height: 25px;
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .section-wrapper {
		&::before {
			width: 313px;
			height: 313px;
			top: initial;
			bottom: -20px;
			right: -144px;
		}
	}
	.main-image {
		position: relative !important;
		top: 12px;
		margin-top: 46px;
		right: initial;
		transform: scale(1.19) translateX(-1px);
	}
  .content {
    margin-top: 60px;
    margin-bottom: 70px;
  }
  .title {
    padding-bottom: 24px;
  }
}
@media #{map-get($display-breakpoints, 'xl-only')} {
	.section-wrapper {
		&:before {
			right: -282px;
		}
	}
	.main-image {
		right: 49px;
	}
}
</style>
